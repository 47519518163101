@value md, xl, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    max-width: 1280px;
    width: 100%;
    padding: 0 md;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: xl;

    & section {
      margin-bottom: lg;

      @media breakpointSmUp {
        margin-bottom: xl;
      }
    }
  }
}
