@value blue from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .seeMoreText {
    color: blue;
  }

  .placeholderProductList {
    display: grid;
    gap: sm;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media breakpointSmUp {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media breakpointMdUp {
      gap: md;
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }

  .modalProductListContainer {
    padding: 0 xl xl;

    li {
      list-style: none;
    }
  }

  .modalProductList {
    display: grid;
    gap: sm;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media breakpointSmUp {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media breakpointMdUp {
      gap: md;
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }

  .placeholderTile {
    list-style-type: none;
    aspect-ratio: 1;
  }

  .attributes {
    margin-top: sm;
  }
}
