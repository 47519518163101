@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown, breakpointMdDown, breakpointLgDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value black, overlayDark from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilySplash from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer component {
  .tileLayout {
    text-align: center;
    list-style: none;
    display: grid;
    grid-column-gap: md;
    grid-row-gap: md;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: lg;

    @media breakpointLgDown {
      grid-template-columns: repeat(2, 1fr);
    }

    @media breakpointSmDown {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: sm;
    }

    a {
      text-decoration: none;
    }
  }

  .tileWrapper {
    min-width: 100%;
    object-fit: cover;
    font-size: 0;
    justify-content: center;
  }

  .background {
    display: grid;
    width: 100%;
    height: 142px;
    background: var(--background);
    border-radius: borderRadiusMedium;
    align-items: center;
    justify-items: center;
    position: relative;
    overflow: hidden;

    &:hover {
      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background-color: overlayDark;
      }
    }

    @media breakpointMdDown {
      height: 125px;
    }

    @media breakpointSmDown {
      height: 87px;
    }
  }

  .titleWrapper {
    display: flex;
    padding: sm md;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .title {
    color: black;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: fontFamilySplash;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    letter-spacing: 0.029px;
  }

  .price {
    color: black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: fontFamilySplash;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.029px;
  }
}
