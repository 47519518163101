@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value lightGrey1, overlayDark, black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdUp, breakpointSmUp, breakpointSmDown, breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .tileContainer {
    overflow-x: scroll;
    scrollbar-width: none;
    margin: 0 -16px; /* Cannot use -md as it gives error - mismatched property value  */

    &::-webkit-scrollbar {
      display: none;
    }

    @media breakpointMdUp {
      overflow-x: unset;
    }
  }

  .tileLayout {
    text-align: center;
    list-style: none;
    display: flex;
    flex-direction: row;
    grid-column-gap: md;
    grid-row-gap: md;
    overflow-x: scroll;
    scrollbar-width: none;
    margin: 0 -16px 0 md; /* Cannot use -md as it gives error - mismatched property value  */
    grid-template-columns: repeat(6, 1fr);
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      display: inline-block;
      width: 258px;
      margin-right: md;
      vertical-align: top;

      @media breakpointMdUp {
        margin: 0;
        width: auto;
        height: auto;
      }
    }

    @media breakpointSmUp {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: md;
      grid-row-gap: md;
      overflow-x: unset;
      word-wrap: break-word;
      white-space: pre-wrap;
      margin: 0 md;
    }

    @media breakpointLgUp {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: md;
      grid-row-gap: md;
      overflow-x: unset;
    }
  }

  .link {
    text-decoration: none;
    color: black;
    text-align: center;

    img {
      display: block;
      min-width: 100%;
      margin-bottom: md;
      object-fit: cover;
      font-size: 0;
      border-radius: borderRadiusMedium;
    }

    &:last-of-type {
      margin-right: md;
    }

    @media breakpointSmUp {
      &:last-of-type {
        margin-right: unset;
      }
    }
  }

  .imgOverlay {
    position: relative;
    font-size: 0;
    background-color: lightGrey1;
    border-radius: borderRadiusMedium;

    &:hover::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: overlayDark;
      object-fit: cover;
      border-radius: borderRadiusMedium;
    }
  }

  .title {
    @media breakpointMdUp {
      text-align: center;
      font-size: 18px;
    }

    @media breakpointSmDown {
      text-align: left;
      width: 258px;
      font-size: 14px;
    }
  }
}
